/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect } from 'react';

import Format from 'src/utils/format';
import { AutoQuote } from 'src/interfaces/IQuotes';
import QuoteCardBase from 'src/components/Quotes/QuoteCard/components/QuoteCardBase/QuoteCardBase';
import QuoteLogo from 'src/components/Quotes/QuoteCard/components/QuoteLogo/QuoteLogo';
import useResponsive from 'src/hooks/useResponsive';
import QuoteCoverages from 'src/components/Quotes/QuoteCoverages/QuoteCoverages';
import { getAutoQuotesCoveragesGroups, getPremiumLabelByType } from 'src/utils/quotesPage';
import { QuoteCardProps } from 'src/components/Quotes/QuoteCard/QuoteCard.types';
import { suffixCSS, cardSummaryCSS } from 'src/components/Quotes/QuoteCard/QuoteCard.style';
import QuoteTags from 'src/components/Quotes/QuoteTags/QuoteTags';
import noop from 'src/utils/noop';
import { getPercentRangeValue } from 'src/utils/percentageCalculation';

const AutoQuoteCard: React.FC<QuoteCardProps<AutoQuote>> = ({
  customCSS,
  quote,
  tags,
  index,
  onCardViewed = noop,
  onQuoteSelect,
  onShowDetailsClicked,
  shouldHideSelectButton = false,
  isAutoCrossSellCampaign
}) => {
  const { assets, premium, carrier, online_bind_url } = quote;
  const { isMobile } = useResponsive();
  const premiumValue = Format.money(premium.value, 0, '$');
  const premiumRange = getPercentRangeValue(premium.value, 15, 15);

  useEffect(() => {
    onCardViewed(quote, index as number);
  }, [onCardViewed, quote, index]);

  const getPremium = () => {
    if (isMobile) {
      return;
    }
    return {
      value: isAutoCrossSellCampaign ? premiumRange : premiumValue,
      label: isAutoCrossSellCampaign ? 'Est. Monthly Premium' : getPremiumLabelByType(premium.type)
    };
  };

  const getActionLabel = (): string | React.ReactElement =>
    isMobile ? (
      <Fragment>
        {isAutoCrossSellCampaign ? premiumRange : premiumValue}
        <span css={suffixCSS}>/{getPremiumLabelByType(premium.type, true)}</span>
      </Fragment>
    ) : (
      'Choose Quote'
    );

  return (
    <QuoteCardBase
      customCSS={customCSS}
      premium={getPremium()}
      shouldHideSelectButton={shouldHideSelectButton}
      actionLabel={getActionLabel()}
      detailsComponent={<QuoteCoverages groups={getAutoQuotesCoveragesGroups(assets)} />}
      showDetailsButton={!isMobile}
      onActionClick={onQuoteSelect}
      buyOnlineQuote={!!online_bind_url}
      onShowDetails={onShowDetailsClicked}
    >
      <div css={cardSummaryCSS(isAutoCrossSellCampaign)}>
        <QuoteLogo logoUrl={carrier.logo_url} logoLabel={carrier.name} />
        <QuoteTags isAutoCrossSellCampaign={isAutoCrossSellCampaign} tags={tags} />
      </div>
    </QuoteCardBase>
  );
};

export default AutoQuoteCard;
