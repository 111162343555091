export default {
  center: { lat: 40.0308843, lng: -83.065123 },
  mapId: 'DEMO_MAP_ID',
  zoom: 17,
  streetViewControl: false,
  styles: [
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'administrative.land_parcel',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'administrative.neighborhood',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    }
  ]
};
