import { css, SerializedStyles } from '@emotion/react';
import { mq, colors } from 'src/theme/variables';
import { text12 } from 'src/theme/typography';

export const cardSummaryCSS = (isAutoCrossSellCampaign?: boolean): SerializedStyles => css`
  display: flex;

  ${isAutoCrossSellCampaign &&
  css`
    flex-direction: column;

    ${mq[0]} {
      flex-direction: row;
    }
  `}
`;

export const tagsCSS = css`
  display: flex;
  align-items: center;
  margin-top: -8px;
  margin-bottom: -8px;

  ${mq[1]} {
    margin-left: 24px;
  }
`;

export const tagCSS = css`
  margin-top: 8px;
`;

export const suffixCSS = css`
  font-size: 12px;
  font-weight: normal;
`;

export const tagWrapperCSS = (isAutoCrossSellCampaign?: boolean): SerializedStyles => css`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  text-align: center;
$
  margin-bottom: ${isAutoCrossSellCampaign ? 0 : 40}px;

  ${mq[0]} {
    margin-bottom: 0;
  }
`;

export const quoteCardDisclaimerCSS = css`
  ${text12};

  color: ${colors.dove};
`;
