/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useCallback } from 'react';

import Footer from 'src/components/Footer/Footer';
import PageHeader from 'src/components/PageHeader/PageHeader';
import ScheduleCall from 'src/components/ScheduleCall/ScheduleCall';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import Page from 'src/components/Page/Page';
import useConfig from 'src/api/config/useConfig';
import { visuallyHidden } from 'src/theme/helpers';
import { useParams, useLocation } from 'react-router-dom';
import FullPageLoader from 'src/shared/components/FullPageLoader/FullPageLoader';
import ScheduleCallAllFunnel from 'src/components/ScheduleCallAllFunnel/ScheduleCallAllFunnel';
import { isObjectInstanceOfLocationState } from './ConfirmationLayout.utils';
import useIdleTimer from 'src/hooks/useIdleManualTimer';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import { getInactivityTime } from 'src/utils/inactivityTime';
import CallMeNowConfirmation from 'src/components/CallMeNowConfirmation/CallMeNowConfirmation';
import useQuestions from 'src/api/questions/useQuestions';

const PAGE_TITLE = 'Matic Insurance Instant Quotes Schedule Call';

const ConfirmationLayout: React.FC = () => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { state } = useLocation();
  const { data, isLoading } = useConfig(gid);
  const { data: questions } = useQuestions(gid);
  const sessionTimeout = getInactivityTime(data?.lock_timeout);
  const shouldStartInactiveTimeout = [Flows.Auto, Flows.AutoBundle, Flows.Home].includes(flow);
  const [isSessionInactive, setIsSessionInactive] = useState(false);
  const [startTimer, resetTimer] = useIdleTimer(sessionTimeout, setIsSessionInactive);

  useEffect(() => {
    document.title = PAGE_TITLE;
    if (data?.servicer_key) {
      analytics.page(SEGMENT.PAGES.CONFIRMATION, {
        flow_type: flow,
        session_gid: gid,
        partner_key: data?.servicer_key
      });
    }
  }, [data, flow, gid]);

  const onRedirectToSessionTimeout = useCallback(() => {
    resetTimer();
    window.location.reload();
  }, [resetTimer]);

  useEffect(() => {
    if (shouldStartInactiveTimeout) {
      startTimer();
    }
  }, [startTimer, shouldStartInactiveTimeout]);

  useEffect(() => {
    if (isSessionInactive) {
      onRedirectToSessionTimeout();
    }
  }, [isSessionInactive, onRedirectToSessionTimeout]);

  const getScheduleCallComponent = () => {
    const isPolicyHolderVariation = isObjectInstanceOfLocationState(state) && state?.isPolicyHolderVariation;
    const selectedCarrier = isObjectInstanceOfLocationState(state) ? state?.carrierName : '';

    const shouldRenderCallMeNowVariation =
      [Flows.Savings, Flows.AllFunnel].includes(flow) &&
      isObjectInstanceOfLocationState(state) &&
      state?.isCallMeNowVariation;

    switch (true) {
      case flow === Flows.Life:
        return <ScheduleCall />;
      case flow === Flows.AllFunnel && isObjectInstanceOfLocationState(state) && !!state?.olbQuotesArray?.length:
        return <ScheduleCallAllFunnel olbQuotesArray={state?.olbQuotesArray} currentQuote={state?.currentQuote} />;
      case shouldRenderCallMeNowVariation:
        return <CallMeNowConfirmation />;
      default:
        return <ScheduleCall isPolicyHolderVariation={isPolicyHolderVariation} selectedCarrier={selectedCarrier} />;
    }
  };

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <Page>
      <PageHeader
        page={SEGMENT.PAGES_KEY.CONFIRMATION}
        phone={
          questions?.answers?.person_is_policyholder === 'yes' ||
          !!questions?.answers?.person_is_policyholder_identified
            ? data?.partner?.cst_phone
            : undefined
        }
      />
      <main>
        <h1 css={visuallyHidden}>{PAGE_TITLE}</h1>
        {getScheduleCallComponent()}
      </main>
      <Footer phone={data?.partner?.agent_phone} page={SEGMENT.PAGES_KEY.CONFIRMATION} />
    </Page>
  );
};

export default ConfirmationLayout;
