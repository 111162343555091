/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef } from 'react';

import { useParams } from 'react-router-dom';

import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import Page from 'src/components/Page/Page';
import Quotes from 'src/components/Quotes/Quotes';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Footer from 'src/components/Footer/Footer';
import { visuallyHidden } from 'src/theme/helpers';
import SEGMENT from 'src/constants/segment';
import useQuestions from 'src/api/questions/useQuestions';
import { useConfig } from 'src/api/config';
import useTrackFullStorySession from 'src/hooks/useTrackFullStorySession';
import useTrackAdBlocker from 'src/hooks/useTrackAdBlocker';
import SoftStopPage from 'src/components/SoftStopPage/SoftStopPage';
import analytics from 'src/utils/analytics';
import { useSession } from 'src/api/session';
import useInitFeatureToggles from 'src/hooks/useInitFeatureToggles/useInitFeatureToggles';

const PAGE_TITLE = 'Matic Insurance Instant Quotes Result';

const QuotesLayout: React.FC = () => {
  const { gid, flow } = useParams() as DefaultLocation;
  const features = useInitFeatureToggles();
  const { data: config } = useConfig(gid);
  const noQuotesRedirectReported = useRef(false);
  const { data: questions } = useQuestions(gid);
  const { data: session } = useSession(flow, gid);

  useTrackFullStorySession(gid);
  useTrackAdBlocker(gid);
  const noEligibleQuotesReturned = session?.status === 'disqualified' && [Flows.AllFunnel, Flows.Home].includes(flow);

  useEffect(() => {
    if (noEligibleQuotesReturned && !noQuotesRedirectReported.current) {
      analytics.track(SEGMENT.NO_QUOTES_STOP_PAGE_REDIRECTED, gid, flow);
      noQuotesRedirectReported.current = true;
    }
  }, [features, flow, gid, noEligibleQuotesReturned]);

  useEffect(() => {
    document.title = PAGE_TITLE;
  }, [gid]);

  return (
    <Page>
      <PageHeader
        page={SEGMENT.PAGES_KEY.QUOTES}
        userName={questions?.answers?.person_first_name as string | undefined}
        phone={
          questions?.answers?.person_is_policyholder === 'yes' ||
          !!questions?.answers?.person_is_policyholder_identified
            ? config?.partner?.cst_phone
            : undefined
        }
      />
      <main>
        <h1 css={visuallyHidden}>{PAGE_TITLE}</h1>
        {noEligibleQuotesReturned ? (
          <SoftStopPage userName={questions?.answers.person_first_name as string} />
        ) : (
          <Quotes />
        )}
      </main>
      <Footer page={SEGMENT.PAGES_KEY.QUOTES} />
    </Page>
  );
};

export default QuotesLayout;
