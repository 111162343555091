/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';
import Container from 'src/kit/Container/Container';
import CoverageOptions from './CoverageOptions/CoverageOptions';
import QuoteBanner from 'src/components/Quotes/QuotesBanner/QuotesBanner';
import { CoverageType } from 'src/interfaces/ICoverageTypes';
import useQuotes from 'src/api/quotes/useQuotes';
import { BundleQuotesResponse, AutoQuote, AutoQuoteGroups, AutoQuotesResponse } from 'src/interfaces/IQuotes';
import { CoverageTypeOptions } from 'src/constants/coverageTypes';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { DefaultLocation, Entity } from 'src/interfaces/IPage';

import { QuoteSelectData, QuotesProps, SelectedQuoteState } from 'src/components/Quotes/Quotes.types';
import ROUTES from 'src/constants/routes';
import QuotesHeaderParam from 'src/components/Quotes/QuotesHeader/QuotesHeaderParam/QuotesHeaderParam';
import QuotesHeader from 'src/components/Quotes/QuotesHeader/QuotesHeader';
import Quantify from 'src/utils/quantify';
import { getAutoQuotesCoveragesGroups, getAutoQuotesHeading, getPremiumLabelByType } from 'src/utils/quotesPage';
import QuoteDetailsDialog from 'src/components/Quotes/QuoteDetailsDialog/QuoteDetailsDialog';
import Format from 'src/utils/format';
import QuoteCoverages from 'src/components/Quotes/QuoteCoverages/QuoteCoverages';
import useResponsive from 'src/hooks/useResponsive';
import {
  coverageOptions,
  quoteCard,
  quotesBanner,
  quotesContainer,
  quotesHeaderEditLink
} from 'src/components/Quotes/Quotes.style';
import useConfig from 'src/api/config/useConfig';
import { QUOTE_TAGS } from 'src/constants/quoteTag';
import AutoQuoteCard from 'src/components/Quotes/QuoteCard/AutoQuoteCard/AutoQuoteCard';
import useQuestions from 'src/api/questions/useQuestions';
import userEventDataService, { UserEvents } from 'src/api/userEventsData/userEventsData';
import { Pages } from 'src/constants/page';

const AutoQuotes: React.FC<QuotesProps> = ({ selectQuote }) => {
  const { isMobile } = useResponsive();
  const { flow, gid } = useParams() as DefaultLocation;
  const { data: questions } = useQuestions(gid);
  const { data: config } = useConfig(gid);
  const { data } = useQuotes<AutoQuotesResponse | BundleQuotesResponse>(flow, gid);
  const quotesData = data as AutoQuotesResponse;
  const [coverageType, setCoverageType] = useState<CoverageType>();
  const [selectedQuote, setSelectedQuote] = useState<SelectedQuoteState<AutoQuote> | null>(null);
  const [shouldHideMblChoose, setShouldHideMblChoose] = useState(false);
  const [isMobileDetailsOpen, setIsMobileDetailsOpen] = useState(false);
  const isAutoCrossSellCampaign = config?.page?.name === Pages.AUTO_CROSS_SELL;

  const answers = questions?.answers || {};
  const { vehicles, drivers } = answers;
  const first_name = answers.person_first_name;
  const address = answers.vehicle_address;
  const isAvibraLead = config?.partner.key === 'avibra';
  const isEmpty = !CoverageTypeOptions.some(({ value }) => quotesData?.quotes[value].length);
  const isLimited = !CoverageTypeOptions.some(({ value }) => (quotesData?.quotes[value] || []).length > 2);
  const editDetailsLink = ROUTES.QUESTIONS.replace(':flow', flow).replace(':gid', gid);
  const coverageTypeQuotes = coverageType ? quotesData?.quotes[coverageType] : [];
  const heading = getAutoQuotesHeading(first_name as string, isLimited, isEmpty);
  const addressValue = (address as Record<string, string>)?.line1;
  const vehiclesNumber = Quantify.anything((vehicles as Entity[]).length, 'Vehicle', 'Vehicles');
  const driversNumber = Quantify.anything((drivers as Entity[]).length, 'Driver', 'Drivers');

  useEffect(() => {
    if (data && !isEmpty && !coverageType) {
      const initCoverageType = getInitCoverageType(data.quotes as AutoQuoteGroups);
      setCoverageType(initCoverageType);
    }
  }, [data, isEmpty, coverageType]);

  useEffect(() => {
    analytics.page(SEGMENT.PAGES.QUOTES, {
      flow_type: flow,
      session_gid: gid
    });
  }, [flow, gid]);

  useEffect(() => {
    if (quotesData && coverageType) {
      const quotesRecap = quotesData?.quotes[coverageType].map(quote => ({
        premium: quote.premium.value,
        carrier_key: quote.carrier.key,
        gid: quote.gid
      }));

      analytics.track(SEGMENT.QUOTES_LIST_VIEWED, gid, flow, {
        coverage_type: coverageType,
        number_of_quotes: coverageType ? quotesData.quotes[coverageType]?.length : 0,
        bundle: false,
        quotes: quotesRecap
      });
    }
  }, [flow, gid, quotesData, coverageType]);

  const onCardViewed = useCallback(
    (quote: AutoQuote, index: number) => {
      if (quote.online_bind_url) {
        analytics.track(SEGMENT.ONLINE_BIND_OFFERED, gid, flow, {
          ordinal: index + 1,
          carrier_key: quote.carrier.key
        });
      }
    },
    [flow, gid]
  );

  const getQuoteSelectData = (quote: AutoQuote): QuoteSelectData => {
    return {
      gid: quote.gid,
      carrier: quote.carrier,
      onlineBindUrl: quote.online_bind_url,
      flow,
      coverageType,
      premium_value: quote.premium.value,
      digital_profile_url: quote.digital_profile_url
    };
  };

  const getInitCoverageType = (quotes: AutoQuoteGroups): CoverageType => {
    switch (true) {
      case !!quotes[CoverageType.Recommended]?.length:
        return CoverageType.Recommended;
      case !!quotes[CoverageType.Basic]?.length:
        return CoverageType.Basic;
      case !!quotes[CoverageType.Premium]?.length:
        return CoverageType.Premium;
      default:
        return CoverageType.Recommended;
    }
  };

  const trackQuoteReviewed = async (quote: AutoQuote, ordinal: number) => {
    analytics.track(SEGMENT.QUOTE_REVIEWED, gid, flow, {
      carrier_key: quote.carrier.key,
      ...(coverageType && { coverage_tier: coverageType }),
      ordinal
    });

    try {
      await userEventDataService.callUserEventReport(UserEvents.QuoteDetailsClicked, gid);
    } catch {}
  };

  const showMobileDetails = (quote: AutoQuote, ordinal: number) => {
    trackQuoteReviewed(quote, ordinal);
    setSelectedQuote({ quote, ordinal });
    setIsMobileDetailsOpen(o => !o);
  };

  const onQuoteSelect = (quote: AutoQuote, ordinal: number, shouldHideChooseButton?: boolean) => () => {
    if (isMobile) {
      showMobileDetails(quote, ordinal);
      setShouldHideMblChoose(!!shouldHideChooseButton);
    } else {
      selectQuote(ordinal, getQuoteSelectData(quote));
    }
  };

  const onQuoteSelectFromDetailsPage = () => {
    if (selectedQuote) {
      const { quote, ordinal } = selectedQuote;
      selectQuote(ordinal, getQuoteSelectData(quote));
    }
  };

  const getMobileDetailsPopup = () => {
    if (selectedQuote) {
      const { quote: q } = selectedQuote;

      return (
        <QuoteDetailsDialog
          logo={{
            url: q.carrier.logo_url,
            label: q.carrier.name
          }}
          price={{
            value: Format.money(q.premium.value, 0, '$'),
            label: getPremiumLabelByType(q.premium.type)
          }}
          contentComponent={<QuoteCoverages groups={getAutoQuotesCoveragesGroups(q.assets)} expandable />}
          onActionClick={onQuoteSelectFromDetailsPage}
          onClose={() => {
            setIsMobileDetailsOpen(false);
            setTimeout(() => setSelectedQuote(null), 100);
            setShouldHideMblChoose(false);
          }}
          shouldHideMblChoose={shouldHideMblChoose}
          isOpen={isMobileDetailsOpen}
        />
      );
    }
  };

  return (
    <Container customCSS={quotesContainer} isAriaLivePolite>
      <QuotesHeader heading={heading}>
        <QuotesHeaderParam>{addressValue}</QuotesHeaderParam>
        <QuotesHeaderParam>{vehiclesNumber}</QuotesHeaderParam>
        <QuotesHeaderParam>{driversNumber}</QuotesHeaderParam>
        {quotesData?.allow_editing_details && (
          <QuotesHeaderParam>
            <Link css={quotesHeaderEditLink} to={editDetailsLink} data-testid="edit-details">
              Edit Details
            </Link>
          </QuotesHeaderParam>
        )}
      </QuotesHeader>

      {quotesData?.quotes && quotesData?.assets && !isEmpty && (
        <CoverageOptions
          customCSS={coverageOptions}
          quotes={quotesData?.quotes}
          selected={coverageType}
          onChange={setCoverageType}
          assets={quotesData?.assets}
        />
      )}

      {coverageTypeQuotes.map((quote: AutoQuote, i: number) => (
        <AutoQuoteCard
          customCSS={quoteCard()}
          key={quote.gid}
          isAutoCrossSellCampaign={isAutoCrossSellCampaign}
          quote={quote}
          coverageType={coverageType}
          tags={i === 0 ? [QUOTE_TAGS.BEST_VALUE] : null}
          index={i}
          shouldHideSelectButton={isAvibraLead && !quote.online_bind_url}
          onCardViewed={onCardViewed}
          onQuoteSelect={onQuoteSelect(quote, i + 1, isAvibraLead && !quote.online_bind_url)}
          onShowDetailsClicked={() => trackQuoteReviewed(quote, i + 1)}
        />
      ))}
      {!isAvibraLead && (
        <QuoteBanner customCSS={quotesBanner} hasNoQuotes={isEmpty} currentQuoteLength={coverageTypeQuotes.length} />
      )}

      {getMobileDetailsPopup()}
    </Container>
  );
};

export default AutoQuotes;
