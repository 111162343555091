import { actionColors, borderRadius, colors, mq } from 'src/theme/variables';
import { css, SerializedStyles } from '@emotion/react';
import { text } from 'src/theme/typography';

export const editPhoneWrapperCSS = (
  hasPhoneError: boolean,
  withPrivacyMatters: boolean,
  isExpirationVariation?: boolean
): SerializedStyles => css`
  background-color: ${colors.white};
  border: ${hasPhoneError ? 2 : 1}px solid ${hasPhoneError ? colors.red : colors.black};
  display: flex;
  border-radius: ${borderRadius}px;
  padding: 4px;
  margin: auto;
  width: 100%;
  box-sizing: border-box;
  position: relative;

  ${withPrivacyMatters &&
  css`
    justify-content: space-between;
  `}

  ${isExpirationVariation &&
  css`
    padding: 0;
    height: 60px;
  `}
`;

export const inputCSS = (hasError: boolean, isExpirationVariation?: boolean): SerializedStyles => css`
  ${text};
  border: none;
  color: ${hasError ? colors.red : 'inherit'};
  position: relative;
  width: 80%;
  font-variant-numeric: lining-nums;
  z-index: 1;
  :focus {
    outline: none;
  }

  ${isExpirationVariation &&
  css`
    width: 100%;
  `}
`;

export const inputWrapperCSS = (isExpirationVariation?: boolean, shouldHideLabel?: boolean): SerializedStyles => css`
  display: flex;
  flex-direction: column;
  text-align: left;
  flex-shrink: 0;
  flex-basis: calc(50% - 12px);
  margin: 6px;
  font-style: italic;

  ${mq[0]} {
    flex-basis: 144px;

    ${isExpirationVariation &&
    css`
      flex-basis: 40%;
      padding: ${shouldHideLabel ? '8px 20px' : '20px'};
      margin: 0px;
    `}
  }
`;

export const labelCSS = (hasError: boolean): SerializedStyles => css`
  ${text};
  font-weight: 400;
  color: ${hasError ? colors.red : colors.dove};
`;

export const buttonCSS = (withPrivacyMatters: boolean, isExpirationVariation?: boolean): SerializedStyles => css`
  flex-grow: 1;

  ${withPrivacyMatters &&
  css`
    flex-grow: 0;
  `}

  ${isExpirationVariation &&
  css`
    width: 50%;
    font-size: 20px;
  `}
`;

export const componentWrapperCSS = css`
  display: flex;
  flex-direction: column;
`;

export const errorCSS = css`
  text-align: left;
  margin-top: 4px;
  background-color: ${colors.white};
  padding: 1px;
`;

export const focusHelper = (isFocused: boolean, hasError: boolean): SerializedStyles => css`
  position: absolute;
  top: -${hasError ? 5 : 4}px;
  right: -${hasError ? 5 : 4}px;
  bottom: -${hasError ? 5 : 4}px;
  left: -${hasError ? 5 : 4}px;
  width: calc(100% + ${hasError ? 6 : 4}px);
  height: calc(100% + ${hasError ? 6 : 4}px);
  border-radius: 7px;
  border: 2px solid ${isFocused ? actionColors.primary : 'transparent'};
  transition: border-color 0.2s ease;
`;
