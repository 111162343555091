import React from 'react';
import Anchor from 'src/kit/Anchor/Anchor';
import LandlordButton from 'src/components/Quotes/QuotesHeader/LandlordButton/LandlordButton';
import { QuoteCoveragesGroup, QuoteCoverageView } from 'src/components/Quotes/QuoteCoverages/QuoteCoverages.types';
import { Coverage, CoverageSimple, HomeCoverages } from 'src/interfaces/ICoverages';
import Format from './format';
import { CoverageHomeTitles, CoverageDescriptions } from 'src/constants/coveragesHome';
import { QuotePremiumType, Vehicle } from 'src/interfaces/IQuotes';
import { CoverageAutoDescriptions, CoverageAutoTitles } from 'src/constants/coveragesAuto';
import { CoverageHomeKeys } from 'src/components/ComparisonModal/ComparisonModal.types';

export const getAutoQuotesHeading = (firstName: string | null, isLimited: boolean, isEmpty: boolean): string => {
  const prefix = firstName ? `${firstName}, y` : 'Y';
  if (isEmpty) {
    return 'Hmmm... Looks like we need more information.';
  } else if (isLimited) {
    return `${prefix}ou’ve got a few options. Let’s get you more.`;
  }
  return `${prefix}ou’ve got options. Lots of options.`;
};

export const getBundleQuotesHeading = (
  firstName: string,
  isEmpty: boolean,
  autoOnly: boolean,
  homeOnly: boolean
): string => {
  switch (true) {
    case isEmpty:
      return 'Hmmm... Looks like we need more information.';
    case autoOnly:
      return `${firstName}, we've found some great auto policies, but we need more information for your home quote.`;
    case homeOnly:
      return (
        `${firstName}, we've found some great home policies, but we need more information for your auto quote ` +
        'to bundle.'
      );
    default:
      return `${firstName}, let’s get you a great policy — for a great price.`;
  }
};

export const getHomeQuotesHeading = (isEmpty: boolean): string => {
  if (isEmpty) {
    return 'Matic Needs More Information';
  }
  return 'Your Quotes are Ready';
};

export const getHomeQuotesSubheading = (
  isLandlord: boolean,
  agentPhone: string,
  isEmpty: boolean,
  quotesLength: number
): React.ReactElement => {
  if (isLandlord) {
    return (
      <>
        <p>
          Matic compared carriers and found the best landlord insurance options. <LandlordButton />
        </p>
        <p>
          For questions, or to customize coverages, call a Matic Advisor at{' '}
          <Anchor anchorType="underline-active" href={`tel:${agentPhone}`}>
            {agentPhone}
          </Anchor>
        </p>
      </>
    );
  }

  if (isEmpty) {
    return (
      <p>
        We’re missing facts about your home which is preventing us from getting quotes. Call our insurance advisors now
        to confirm a few property details and see how much you can save.
      </p>
    );
  }

  return (
    <p>
      Matic compared quotes from 40+ insurance carriers and here{' '}
      {quotesLength === 1 ? 'is the best option' : `are the ${quotesLength} best options`} we chose for you. If you’d
      like to customize any of these coverages, speak to an Advisor at{' '}
      <Anchor anchorType="underline-active" href={`tel:${agentPhone}`}>
        {agentPhone}
      </Anchor>
    </p>
  );
};

export const isSimpleCoverage = (coverage: Coverage): coverage is CoverageSimple => coverage.type === 'simple';

export const isEmptyCoverage = (coverage: Coverage): boolean =>
  isSimpleCoverage(coverage) ? !coverage.value : !coverage.personal;

export const getCoveragePrintValue = (coverage: Coverage): any => {
  return isSimpleCoverage(coverage)
    ? typeof coverage.value === 'string'
      ? coverage.value
      : Format.money(coverage.value, 2, '$')
    : `${Format.money(coverage.personal || 0, 2, '$')} / ${Format.money(coverage.accident || 0, 2, '$')}`;
};

export const getHomeQuoteCardViewCoverages = (coverages: HomeCoverages, withType?: boolean): QuoteCoverageView[] => {
  return [
    {
      title: CoverageHomeTitles.dwelling,
      value: Format.money(coverages.dwelling || '', 2, '$'),
      description: CoverageDescriptions.dwelling,
      type: withType ? CoverageHomeKeys.Dwelling : undefined
    },
    {
      title: CoverageHomeTitles.other_structures,
      value: Format.money(coverages.other_structures || '', 2, '$'),
      description: CoverageDescriptions.other_structures,
      type: withType ? CoverageHomeKeys.OtherStructures : undefined
    },
    {
      title: CoverageHomeTitles.personal_property,
      value: Format.money(coverages.personal_property || '', 2, '$'),
      description: CoverageDescriptions.personal_property,
      type: withType ? CoverageHomeKeys.PersonalProperty : undefined
    },
    {
      title: CoverageHomeTitles.loss_of_use,
      value: Format.money(coverages.loss_of_use || '', 2, '$'),
      description: CoverageDescriptions.loss_of_use,
      type: withType ? CoverageHomeKeys.LossOfUse : undefined
    },
    {
      title: CoverageHomeTitles.personal_liability,
      value: Format.money(coverages.personal_liability || '', 2, '$'),
      description: CoverageDescriptions.personal_liability,
      type: withType ? CoverageHomeKeys.PersonalLiability : undefined
    },
    {
      title: CoverageHomeTitles.medical_payments,
      value: Format.money(coverages.medical_payments || '', 2, '$'),
      description: CoverageDescriptions.medical_payments,
      type: withType ? CoverageHomeKeys.MedicalPayments : undefined
    }
  ];
};

export const getHomeQuotesCoveragesGroups = (coverages: HomeCoverages): QuoteCoveragesGroup[] => {
  return [
    {
      title: 'Policy Coverage',
      type: 'home',
      key: 'Policy Coverage',
      coverages: getHomeQuoteCardViewCoverages(coverages)
    }
  ];
};

const getAfSingleQuoteCardViewCoverages = (coverages: HomeCoverages): QuoteCoverageView[] => {
  return [
    {
      title: CoverageHomeTitles.dwelling,
      value: Format.money(coverages.dwelling || '', 2, '$'),
      description: CoverageDescriptions.dwelling,
      type: CoverageHomeKeys.Dwelling
    },
    {
      title: CoverageHomeTitles.other_structures,
      value: Format.money(coverages.other_structures || '', 2, '$'),
      description: CoverageDescriptions.other_structures,
      type: CoverageHomeKeys.OtherStructures
    },
    {
      title: CoverageHomeTitles.personal_property,
      value: Format.money(coverages.personal_property || '', 2, '$'),
      description: CoverageDescriptions.personal_property,
      type: CoverageHomeKeys.PersonalProperty
    },
    {
      title: CoverageHomeTitles.loss_of_use,
      value: Format.money(coverages.loss_of_use || '', 2, '$'),
      description: CoverageDescriptions.loss_of_use,
      type: CoverageHomeKeys.LossOfUse
    },
    {
      title: CoverageHomeTitles.personal_liability,
      value: Format.money(coverages.personal_liability || '', 2, '$'),
      description: CoverageDescriptions.personal_liability,
      type: CoverageHomeKeys.PersonalLiability
    },
    {
      title: CoverageHomeTitles.medical_payments,
      value: Format.money(coverages.medical_payments || '', 2, '$'),
      description: CoverageDescriptions.medical_payments,
      type: CoverageHomeKeys.MedicalPayments
    }
  ];
};

export const getAfSingleQuoteCoveragesGroups = (coverages: HomeCoverages): QuoteCoveragesGroup[] => {
  return [
    {
      title: 'Policy Coverage',
      type: undefined,
      key: 'Policy Coverage',
      coverages: getAfSingleQuoteCardViewCoverages(coverages)
    }
  ];
};

export const getAutoQuotesCoveragesGroups = (assets: Vehicle[]): QuoteCoveragesGroup[] => {
  return assets.map(({ year, make, model, coverages }) => ({
    title: `${year} ${make} ${model}`,
    type: 'auto',
    key: `${year} ${make} ${model}`,
    coverages: coverages.map(c => ({
      title: CoverageAutoTitles[c.key!] || Format.snake(c.key!),
      value: isEmptyCoverage(c) ? 'Not Covered' : getCoveragePrintValue(c),
      description: CoverageAutoDescriptions[c.key!]
    }))
  }));
};

export const getPremiumLabelByType = (type: QuotePremiumType, short?: boolean): string => {
  switch (type) {
    case QuotePremiumType.Monthly:
      return short ? 'mo' : 'A Month';
    case QuotePremiumType.Semiannual:
      return short ? '6 mo' : '6 mo. Premium';
    case QuotePremiumType.Annual:
      return short ? 'y' : 'Est. Annual Premium';
  }
};
