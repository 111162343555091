import { css, SerializedStyles } from '@emotion/react';
import { text12, text14 } from 'src/theme/typography';
import { colors as c } from 'src/theme/variables';

export const securityContainerCSS = (
  nonIconVariation?: boolean,
  isExpirationVariation?: boolean
): SerializedStyles => css`
  ${!nonIconVariation &&
  css`
    max-width: 408px;
  `}

  ${isExpirationVariation &&
  css`
    max-width: unset;
  `}

  margin-top: 12px;
`;

export const securityHeadingCSS = css`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;

  h2 {
    ${text14};
    line-height: 20px;
    margin: 0;
  }

  img {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
`;

export const securityLanguageDescriptionCSS = (nonIconVariation?: boolean): SerializedStyles => css`
  ${text12};
  line-height: 14px;
  font-weight: 500;
  text-align: start;
  color: ${c.mineshaft};
  margin-bottom: 8px;

  ${nonIconVariation &&
  css`
    color: unset;
    line-height: 20px;
  `}
`;

export const securityLanguageLinkCSS = css`
  color: ${c.mineshaft};
  border-color: ${c.mineshaft};
  border-width: 0.5px;
`;

export const maticPoliciesDisclosureCSS = css`
  padding-top: 5px;
`;
