import { css, SerializedStyles } from '@emotion/react';

import { colors, mq, sizes } from 'src/theme/variables';
import { text12, text24, text32, text40 } from 'src/theme/typography';

export const contentCSS = css`
  ${mq[1]} {
    margin-top: 20px;
    flex-direction: row;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`;

export const ratingCSS = css`
  flex-shrink: 0;
  margin-bottom: 24px;
  ${mq[0]} {
    margin-bottom: 20px;
  }
  ${mq[0]} {
    margin-bottom: 0;
  }
`;

export const disclaimerBaseCSS = css`
  color: ${colors.warmGray};
  line-height: 16px;
`;

export const disclaimerCSS = css`
  ${text12};
  ${disclaimerBaseCSS};
  margin-top: 12px;
  margin-left: 24px;
`;

export const carouselCSS = css`
  margin: 0 -${sizes.paddingBodyHorizontalMobile}px;
`;

export const cardCSS = css`
  width: 33.33%;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
`;

export const desktopViewCSS = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  ${mq[1]} {
    margin-left: 24px;
  }
`;

export const headerCSS = (isExpirationVariation?: boolean): SerializedStyles => css`
  ${text24};
  margin: 0 0 12px;
  text-align: center;

  ${mq[0]} {
    ${text32};
    text-align: left;
  }

  ${isExpirationVariation &&
  css`
    max-width: 50%;
    margin: auto;

    ${mq[0]} {
      max-width: unset;
      ${text40};
      text-align: center;
    }
  `}
`;
