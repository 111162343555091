/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { QuoteCardKeyValuesProps } from './QuoteCardKeyValues.types';
import {
  containerCSS,
  labelRowCSS,
  metadataWrapperCSS,
  premiumRowCSS,
  valueRowCSS,
  bndlMsqPremiumValueCSS
} from './QuoteCardKeyValues.style';
import Format from 'src/utils/format';
import { getPremiumLabelByType } from 'src/utils/quotesPage';
import useResponsive from 'src/hooks/useResponsive';
import { AUTOMATION_TEST_IDS } from 'src/constants/automationTestIds';
import { getPercentRangeValue } from 'src/utils/percentageCalculation';

const QuoteCardKeyValues: React.FC<QuoteCardKeyValuesProps> = ({
  customCSS,
  premium,
  deductible,
  highlightPremium,
  multiPolicyPremium,
  isBndToggleOn
}) => {
  const { isMobile } = useResponsive();

  const premiumValue = useMemo(() => {
    if (isBndToggleOn) {
      return Format.money(multiPolicyPremium || premium.value, 0, '$');
    }

    return getPercentRangeValue(premium.value, 15, 15);
  }, [isBndToggleOn, multiPolicyPremium, premium.value]);

  const bndlMsgQuote = isBndToggleOn && !!multiPolicyPremium;

  return (
    <div css={[containerCSS, customCSS]}>
      {!isMobile && (
        <div css={metadataWrapperCSS}>
          {<div css={valueRowCSS}>{Format.money(deductible, 9, '$')}</div>}
          <div css={labelRowCSS()}>Deductible</div>
        </div>
      )}
      <div css={metadataWrapperCSS}>
        {bndlMsgQuote && <div css={bndlMsqPremiumValueCSS}>{Format.money(premium.value, 0, '$')}</div>}
        <div css={[valueRowCSS, premiumRowCSS(highlightPremium)]} data-testid={AUTOMATION_TEST_IDS.QUOTE.PREMIUM}>
          {premiumValue}
        </div>
        <div css={labelRowCSS(highlightPremium)}>{getPremiumLabelByType(premium.type, false)}</div>
      </div>
    </div>
  );
};

export default QuoteCardKeyValues;
