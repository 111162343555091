export enum EXPERIMENT_VARIANTS_ENUM {
  CONTROL = 'control_variation',
  TEST_A = 'test_variation_a',
  TEST_B = 'test_variation_b',
  TEST_C = 'test_variation_c'
}

export type EXPERIMENT_REPORT_VARIANTS_TYPE = '0' | '1' | '2' | '3';

export enum FEATURE_TOGGLES_IDS_ENUM {
  PLACEHOLDER = 'cx.exp.000.placeholder' // this enum can't be empty
}
